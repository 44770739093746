.button {
    max-width: 280px;
    width: 100%;
    padding: 20px;
    color: #F1F2F4;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.531px;
    outline: none;
    border: 3px solid #FBD144;
    border-radius: 10px;
    background: #FBD144;
    transition: all 0.3s ease;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
}

.def-button {
    background: transparent;
    color: #A3ABB2;
}

@media only screen and (max-width: 576px) {
    .button {
        max-width: 180px;
        padding: 10px;
        font-size: 18px;
        border: 1px solid #FBD144;
    }
}