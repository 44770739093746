.user-hero {
    padding: 60px 0 40px;
    max-width: 630px;
    width: 100%;
    margin: 0 auto;
}

.logo {
    color: #A3ABB2;
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 500;
}

.user-hero-inner {
    display: flex;
    align-items: center;
}

.user-avatar-border {
    display: flex;
    padding: 5px;
    border-radius: 50%;
    border: 4px solid #F7D039;
    object-fit: cover;
    object-position: center top;
    // transform: rotateZ(-25deg);
    max-width: 192px;
    max-height: 192px;
}

.user-avatar-img {
    border-radius: 50%;
    width: 100%;
    background-color: #F7D039;
}

.user-hero-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 40px;
}

.user-fullname {
    color: #F1F2F4;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    text-transform: capitalize;
    margin-bottom: 4px;
}

.user-job {
    color: #A3ABB2;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
}

.user-social-links {
    display: flex;
    align-items: center;
    gap: 10px;
}


.social-link-item {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 576px) {
    .user-hero {
        padding: 30px 0 20px;
        max-width: 420px;
        width: 100%;
        margin: 0 auto;
    }

    .user-hero-info {
        padding-left: 20px;
    }

    .user-fullname {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 2px;
    }

    .user-job {
        font-size: 18px;
    }


    .social-link-item {
        width: 20px;
        height: 20px;
    }

    .user-avatar-img {
        max-width: 120px;
        max-height: 120px;
    }

    .user-avatar-border {
        padding: 3px;
        border: 1px solid #F7D039;
    }

}

@media only screen and (max-width: 454px) {
    .user-hero {
        max-width: 100%;
    }

    .user-hero-info {
        padding-left: 15px;
    }

    .user-fullname {
        font-size: 18px;
        line-height: 26px;
    }

    .user-job {
        font-size: 14px;
        margin-bottom: 15px;
    }


    .social-link-item {
        width: 18px;
        height: 18px;
    }

    .user-avatar-img {
        max-width: 100px;
        max-height: 100px;
    }
}

@media only screen and (max-width: 365px) {
    .user-hero-info {
        padding-left: 10px;
    }

    .user-fullname {
        font-size: 18px;
        line-height: 22px;
    }

    .user-avatar-img {
        max-width: 80px;
        max-height: 80px;
    }
}


@media only screen and (max-width: 340px) {

    .user-fullname {
        font-size: 16px;
    }

    .user-job {
        font-size: 12px;
        margin-bottom: 8px;
    }

    .user-avatar-img {
        max-width: 80px;
        max-height: 80px;
    }
}