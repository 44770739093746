@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100vh;
}

body {
    margin: 0;
    background: #E9EBEC;
    background: #0C151D;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    height: 100%;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.main {
    flex-grow: 1;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.container {
    max-width: 800px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
}

a {
    text-decoration: none;
    font-weight: 500;
}

::-webkit-scrollbar {
    width: 0px;
    cursor: pointer;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
}

::-webkit-scrollbar-thumb {
    background: #FBD144;
    cursor: pointer;
}


.page-enter {
    opacity: 0;
}

.page-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}

.page-exit {
    opacity: 1;
}

.page-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}