.portfolio {
    padding-bottom: 50px;
}

.portfolio-title {
    color: #F1F2F4;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.portfolio-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.portfolio-card {
    display: flex;
    gap: 15px;
    border-bottom: 1px solid #F7D039;
    padding: 5px 0px 20px;
}

.portfolio-card-img {
    max-width: 280px;
    width: 100%;
    border-radius: 14px;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        img {
            width: 280px;
            border-radius: 12px;
            height: 160px;
            object-fit: cover;
        }
    }
}

.portfolio-card-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.portfolio-card-title {
    margin: 0;
    color: #F1F2F4;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.portfolio-card-url {
    color: #006fee;
    font-size: 18px;
}

.portfolio-desc {
    margin: 0;
    color: #F1F2F4;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 5px;
}

.portfolio-card-task-title {
    color: #ef9c3d;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.portfolio-card-task-list {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.portfolio-card-task-item {
    position: relative;
    padding-left: 17px;
    color: #A3ABB2;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;

    &::before {
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        content: '#';
    }
}

.portfolio-card-lang-title {
    margin-top: 10px;
}

.portfolio-card-lang-list {
    flex-direction: row;
    flex-wrap: wrap;
}

.portfolio-card-lang-item {
    padding-left: 0;
    padding-right: 7px;

    &::before {
        display: none;
    }
}

@media only screen and (max-width: 576px) {

    .portfolio-title {
        font-size: 22px;
        line-height: 25px;
        margin-bottom: 5px;
    }

    .portfolio-card {
        flex-direction: column;
    }

    .portfolio-card-img {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .portfolio-card-lang-title {
        margin: 0;
        margin-bottom: 8px;
    }

    .portfolio-card-header {
        margin-bottom: 15px;
    }

    .portfolio-card-title {
        font-size: 18px;
    }
}

@media only screen and (max-width: 425px) {

    .portfolio-card {
        margin-bottom: 20px;
    }

    .portfolio-card-img {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 100%;

        a {
            img {
                width: 100%;
                height: 190px;
            }
        }
    }

    .portfolio-card-lang-title {
        margin: 0;
        margin-bottom: 0px;
    }

    .portfolio-card-header {
        margin-bottom: 15px;
    }

    .portfolio-card-title {
        font-size: 18px;
    }
}