.career {
    padding: 20px 10px;
}

.career-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.career-item {
    text-align: center;
}

.career-item p {
    color: #A3ABB2;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media only screen and (max-width: 576px) {
    .career-item p {
        font-size: 18px;
        font-weight: 500;
    }
}

@media only screen and (max-width: 440px) {
    .career {
        padding: 20px 0px;
    }

    .career-list {
        gap: 6px;
    }

    .career-item p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 350) {
    .career {
        padding: 10px 0px;
    }

    .career-item p {
        font-size: 12px;
    }

}