

.default-nav-list {
    display: flex;
    align-items: center;
    gap: 20px;
}

.nav-item-link {
    text-decoration: none;
    color: #A3ABB2;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}